import * as React from 'react';

import * as classNames from 'classnames';
import * as DOMPurify from 'dompurify';

import { Alert, Button, Link, TextField } from '@emburse/embark-core';

import { injectCastleRequestToken } from '@ReactComponents/../utils/castle';
import { Disclosure } from '@ReactComponents/Disclosure';
import { LogoContainer } from '@ReactComponents/Layout/LogoContainer';
import { RedesignedLoginForm } from '@ReactComponents/RedesignedLoginForm/RedesignedLoginForm';
import { EmburseAccountsPageContainer } from '@ReactComponents/Shared/Account/EmburseAccountsPageContainer';
import { TestSiteBanner } from '@ReactComponents/Shared/TestSiteBanner/TestSiteBanner';

import { WindowErrors, emburseLoginEnabled } from './loginHelper';

import * as style from './LoginPage.styl';

interface ILoginPageProps {
  csrf: string;
  locals: WindowErrors;
}

interface IExtendedWindow extends Window {
  featureFlags: any;
}

export const LoginPage: React.FC<ILoginPageProps> = ({ csrf, locals }) => {
  const renderLoginForm = () => {
    return (
      <form method="POST" action="/login" id="login" autoComplete="off" onSubmit={onSubmit}>
        <TestSiteBanner />
        <input type="hidden" name="_csrf" value={csrf} />
        <TextField
          id="emailField"
          type="email"
          name="email"
          className="fullInput focus"
          placeholder="Email"
          required
          autoFocus
          fullWidth
          style={{ marginBottom: '20px' }}
        />
        <TextField
          id="passwordField"
          type="password"
          name="password"
          className="fullInput"
          placeholder="Password"
          autoComplete="off"
          fullWidth
          required
          style={{ marginBottom: '20px' }}
        />
        <Button id="loginButton" className="login" type="submit" size="small">
          Log In
        </Button>
        <div className="forgotContainer">
          <Link href="/reset_password">Forgot Password?</Link>
        </div>
      </form>
    );
  };

  const renderDowntime = () => {
    return <div>{window?.whitelabelConfiguration?.tags?.brandName} is currently unavailable due to scheduled maintenance.</div>;
  };

  const createErrorDiv = (className: string, html: string) => {
    const sanitized = DOMPurify.sanitize(html); // this should be serverside html
    return <div className={className} dangerouslySetInnerHTML={{ __html: sanitized }} />;
  };

  const renderErrors = () => {
    let ele;
    if (locals?.error && locals.error.length) {
      ele = createErrorDiv('error', locals.error);
    } else if (locals?.flashError && locals.flashError.length > 0) {
      ele = createErrorDiv('error', locals.flashError);
    } else if (locals?.flashSuccess && locals.flashSuccess.length > 0) {
      ele = createErrorDiv('flashSuccess', locals.flashSuccess);
    }
    return ele;
  };

  function renderRedirectNotice() {
    return <div>Please wait while you are redirected to Emburse login </div>;
  }

  function renderRedesignedLoginContent() {
    return (
      <EmburseAccountsPageContainer>
        <RedesignedLoginForm csrf={csrf} onSubmit={onSubmit} />
      </EmburseAccountsPageContainer>
    );
  }

  function renderMainContent() {
    if ((window as unknown as IExtendedWindow)?.FEATURES?.planned_database_downtime) {
      return renderDowntime();
    }

    if (window.FEATURES?.emburse_login && emburseLoginEnabled(window.location?.search)) {
      return renderRedirectNotice();
    }

    return renderLoginForm();
  }

  function renderMainContentContainer() {
    if (window.FEATURES?.new_login_page) {
      return renderRedesignedLoginContent();
    }

    return (
      <>
        <div className={classNames('logoContainer', `container-whitelabel-${window.whitelabelConfiguration.id.toLowerCase()}`)}>
          <LogoContainer style={{ padding: '0px' }} />
        </div>
        <div className="onboardingContainer">{renderMainContent()}</div>
        <Alert
          severity="info"
          alertTitle="Abacus is migrating to Emburse Spend on April 23, 2025"
          style={{ maxWidth: '570px', margin: '0 auto', boxSizing: 'border-box', marginTop: '20px' }}
        >
          <ul>
            <li>Please continue using Abacus until April 23, 2025.</li>
            <li>
              On April 23, log in using your existing Abacus credentials at{' '}
              <a href="https://spend.emburse.com/login">spend.emburse.com/login</a> or through the Emburse Spend mobile app,{' '}
              <a href="https://apps.apple.com/ca/app/emburse-spend/id1609492240">iOS</a> or{' '}
              <a href="https://play.google.com/store/apps/details?id=com.emburse.spend.android&pcampaignid=web_share">Android</a>.
            </li>
          </ul>
        </Alert>
        <div className="disclosureContainer">
          <Disclosure whitelabelConfiguration={window.whitelabelConfiguration} />
        </div>
      </>
    );
  }

  function onSubmit(e: any) {
    injectCastleRequestToken(e);
    e.nativeEvent.submitter.disabled = true;
    setTimeout(() => {
      e.nativeEvent.submitter.disabled = false;
    }, 2000); // in case of an unexpected error (e.g network issue), allows the form to be submitted again after a short delay
  }

  return (
    <div className={style.global}>
      <div className="onboardingWrapper">
        {renderErrors()}
        {renderMainContentContainer()}
      </div>
    </div>
  );
};
