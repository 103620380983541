export enum ONBOARDING_STATUS_VALUES {
  COMPLETED = 'completed',
  IN_PROGRESS = 'in_progress'
}

export const ONBOARDING_STATUS_VALUES_LIST = Object.values(ONBOARDING_STATUS_VALUES);

export enum SETUP_WIZARD_FIELDS {
  REIMBURSABLE_EXPENSE = 'reimbursable_expense',
  EMBURSE_CARDS = 'emburse_cards',
  CORPORATE_CARDS = 'corporate_cards',
  BUSINESS_CARDS = 'business_cards',
  MANUAL_CARD_FEED = 'manual_card_feed',
  AMEX_CARDS_TYPE = 'amex_cards_type',
  AMEX_VIRTUAL_CARDS = 'amex_virtual_cards',
  ACCOUNTING_INTEGRATION = 'accounting_integration',
  DESIRED_NUMBER_OF_USERS = 'desired_number_of_users'
}

export const SETUP_WIZARD_FIELD_VALUES = Object.values(SETUP_WIZARD_FIELDS);

export enum DESIRED_NUMBER_OF_USERS {
  UNDER_10 = 'under_10',
  FROM_11_TO_50 = '11_to_50',
  FROM_51_TO_100 = '51_to_100',
  FROM_101_PLUS = '101_plus'
}

export const DESIRED_NUMBER_OF_USERS_VALUES = Object.values(DESIRED_NUMBER_OF_USERS);

export enum AMEX_CARD_TYPE {
  AMEX_CORPORATE_CARD = 'amex_corporate_card',
  AMEX_BUSINESS_CARD = 'amex_business_card'
}

export const AMEX_CARD_TYPE_VALUES = Object.values(AMEX_CARD_TYPE);

export enum WIZARD_VALUES {
  YES = 'yes',
  NO = 'no',
  PENDING = 'pending',
  MAYBE = 'maybe'
}

export const WIZARD_VALUES_LIST = Object.values(WIZARD_VALUES);

export enum ONBOARDING_CHECKLIST_FIELDS {
  GUIDED_IMPLEMENTATION_SETUP = 'guided_implementation_setup',
  GUIDED_IMPLEMENTATION_SETUP_COMPLETED_BY = 'guided_implementation_setup_completed_by',
  WELCOME_GUIDED_IMPLEMENTATION_SETUP = 'welcome_guided_implementation_setup',
  WELCOME_GUIDED_IMPLEMENTATION_SETUP_COMPLETED_BY = 'welcome_guided_implementation_setup_completed_by',
  REIMBURSABLE_EXPENSES_SETUP = 'reimbursable_expenses_setup',
  ATTEND_WEBINARS_SETUP = 'attend_webinars_setup',
  ATTEND_WEBINARS_SETUP_COMPLETED_BY = 'attend_webinars_setup_completed_by',
  BANK_ACCOUNT_SETUP = 'bank_account_setup',
  REIMBURSABLE_EXPENSES_SETUP_COMPLETED_BY = 'reimbursable_expenses_setup_completed_by',
  EMBURSE_CARDS_SETUP = 'emburse_cards_setup',
  EMBURSE_CARDS_FUNDING_ACCOUNT_SETUP = 'emburse_cards_funding_account_setup',
  EMBURSE_CARDS_FUNDS_SETUP = 'emburse_cards_funds_setup',
  EMBURSE_CARDS_ISSUE_CARD_SETUP = 'emburse_cards_issue_card_setup',
  EMBURSE_CARDS_SETUP_COMPLETED_BY = 'emburse_cards_setup_completed_by',
  CARD_PROGRAM_SETUP = 'card_program_setup',
  CARD_PROGRAM_SETUP_COMPLETED_BY = 'card_program_setup_completed_by',
  ACCOUNTING_INTEGRATION_SETUP = 'accounting_integration_setup',
  CATEGORIES_SETUP = 'categories_setup',
  EXPENSE_FIELDS_SETUP = 'expense_fields_setup',
  TEAM_FIELDS_SETUP = 'team_fields_setup',
  ACCOUNTING_INTEGRATION_SETUP_COMPLETED_BY = 'accounting_integration_setup_completed_by',
  POLICY_SETUP = 'policy_setup',
  POLICY_SETUP_COMPLETED_BY = 'policy_setup_completed_by',
  INVITE_USERS_SETUP = 'invite_users_setup',
  INVITE_USERS_SETUP_COMPLETED_BY = 'invite_users_setup_completed_by'
}

export const ONBOARDING_CHECKLIST_FIELD_VALUES = Object.values(ONBOARDING_CHECKLIST_FIELDS);

export enum ONBOARDING_CHECKLIST_VALUES {
  COMPLETED = 'completed',
  PENDING = 'pending',
  SKIPPED = 'skipped',
  STARTED = 'started', // Needed for Policy Setup to show that the user has started the process
  DISMISSED = 'dismissed' // Needed for Guided Implementation to show that the user has dismissed the process
}

export const ONBOARDING_CHECKLIST_VALUES_LIST = Object.values(ONBOARDING_CHECKLIST_VALUES);

export const ONBOARDING_CHECKLIST_INCOMPLETE_STATES: ONBOARDING_CHECKLIST_VALUES[] = [ONBOARDING_CHECKLIST_VALUES.PENDING];

interface OnboardingChecklistFieldsMapping {
  [key: string]: ONBOARDING_CHECKLIST_FIELDS[];
}

export const ONBOARDING_CHECKLIST_FIELDS_MAPPING: OnboardingChecklistFieldsMapping = {
  reimbursable_expense: [ONBOARDING_CHECKLIST_FIELDS.REIMBURSABLE_EXPENSES_SETUP, ONBOARDING_CHECKLIST_FIELDS.BANK_ACCOUNT_SETUP],
  emburse_cards: [
    ONBOARDING_CHECKLIST_FIELDS.EMBURSE_CARDS_SETUP,
    ONBOARDING_CHECKLIST_FIELDS.EMBURSE_CARDS_FUNDING_ACCOUNT_SETUP,
    ONBOARDING_CHECKLIST_FIELDS.EMBURSE_CARDS_FUNDS_SETUP,
    ONBOARDING_CHECKLIST_FIELDS.EMBURSE_CARDS_ISSUE_CARD_SETUP
  ],
  corporate_cards: [ONBOARDING_CHECKLIST_FIELDS.CARD_PROGRAM_SETUP],
  business_cards: [ONBOARDING_CHECKLIST_FIELDS.CARD_PROGRAM_SETUP],
  manual_card_feed: [ONBOARDING_CHECKLIST_FIELDS.CARD_PROGRAM_SETUP],
  amex_cards_type: [ONBOARDING_CHECKLIST_FIELDS.CARD_PROGRAM_SETUP],
  amex_virtual_cards: [ONBOARDING_CHECKLIST_FIELDS.CARD_PROGRAM_SETUP],
  accounting_integration: [
    ONBOARDING_CHECKLIST_FIELDS.ACCOUNTING_INTEGRATION_SETUP,
    ONBOARDING_CHECKLIST_FIELDS.CATEGORIES_SETUP,
    ONBOARDING_CHECKLIST_FIELDS.EXPENSE_FIELDS_SETUP,
    ONBOARDING_CHECKLIST_FIELDS.TEAM_FIELDS_SETUP
  ],
  desired_number_of_users: [ONBOARDING_CHECKLIST_FIELDS.INVITE_USERS_SETUP]
};

export const ONBOARDING_CHECKLIST_MAIN_FIELDS = {
  REIMBURSABLE_EXPENSES_SETUP: [ONBOARDING_CHECKLIST_FIELDS.REIMBURSABLE_EXPENSES_SETUP, ONBOARDING_CHECKLIST_FIELDS.BANK_ACCOUNT_SETUP],
  EMBURSE_CARDS_SETUP: [
    ONBOARDING_CHECKLIST_FIELDS.EMBURSE_CARDS_SETUP,
    ONBOARDING_CHECKLIST_FIELDS.EMBURSE_CARDS_FUNDING_ACCOUNT_SETUP,
    ONBOARDING_CHECKLIST_FIELDS.EMBURSE_CARDS_FUNDS_SETUP,
    ONBOARDING_CHECKLIST_FIELDS.EMBURSE_CARDS_ISSUE_CARD_SETUP
  ],
  CARD_PROGRAM_SETUP: [ONBOARDING_CHECKLIST_FIELDS.CARD_PROGRAM_SETUP],
  ACCOUNTING_INTEGRATION_SETUP: [
    ONBOARDING_CHECKLIST_FIELDS.ACCOUNTING_INTEGRATION_SETUP,
    ONBOARDING_CHECKLIST_FIELDS.CATEGORIES_SETUP,
    ONBOARDING_CHECKLIST_FIELDS.EXPENSE_FIELDS_SETUP,
    ONBOARDING_CHECKLIST_FIELDS.TEAM_FIELDS_SETUP
  ],
  POLICY_SETUP: [ONBOARDING_CHECKLIST_FIELDS.POLICY_SETUP],
  INVITE_USERS_SETUP: [ONBOARDING_CHECKLIST_FIELDS.INVITE_USERS_SETUP]
};
